// React & Utilities
import * as React from 'react';
import { useEffect } from 'react';
import type { NextPage } from 'next';
import { gtm } from '@/lib/gtm/gtm';

// Material UI
import { Box } from '@mui/material';
import { Tabs } from '@mui/base/Tabs';

// Layout Components
import { AuthGuard } from '@/components/authentication/auth-guard';
import { DashboardLayout } from '@/components/_shared/layouts/dashboard/dashboard-layout';
import { HeadTitle } from '@/components/_shared/page-elements/head-title';
import { PageHeader } from '@/components/_shared/page-elements/page-header/page-header';

// Page Content, Sections & Components
import { BusinessIntelligenceOverviewComponent } from '@/components/dashboard/business-intelligence/intelligence-overview/business-intelligence-overview-component';

// Page Variables
const headTitle = 'Business Intelligence | RipeMetrics';
const pageTitle = 'Business Intelligence';
const timeframeSelector = true;
const IntelligenceOverview: NextPage = () => {
  useEffect(() => {
    gtm.push({
      event: 'page_view'
    });
  }, []);
  return <>
      <HeadTitle headTitle={headTitle} data-sentry-element="HeadTitle" data-sentry-source-file="intelligence-overview.tsx" />
      <Box component="main" data-sentry-element="Box" data-sentry-source-file="intelligence-overview.tsx">
        <Tabs data-sentry-element="Tabs" data-sentry-source-file="intelligence-overview.tsx">
          <PageHeader title={pageTitle} timeframeSelector={timeframeSelector} data-sentry-element="PageHeader" data-sentry-source-file="intelligence-overview.tsx" />
          <div>
            <BusinessIntelligenceOverviewComponent data-sentry-element="BusinessIntelligenceOverviewComponent" data-sentry-source-file="intelligence-overview.tsx" />
          </div>
        </Tabs>
      </Box>
    </>;
};
IntelligenceOverview.getLayout = page => <AuthGuard>
    <DashboardLayout>{page}</DashboardLayout>
  </AuthGuard>;
export default IntelligenceOverview;