import React, { FC, useRef, useEffect, useState } from 'react';
import { UnresolvedIssuesComponent } from './components/unresolved-issues/unresolved-issues-component';
import { NetPromoterScoreComponent } from './components/net-promoter-score/net-promoter-score-component';
import { BusinessIntelligenceSalesHistorySplineChart } from './components/sales-history-spline-chart/business-intelligence-sale-history-spline-chart';
import { BusinessIntelligenceStatCards } from './components/business-intelligence-overview-stat-cards/business-intelligence-overview-stat-cards';
import StandardizedElementHeightLayout from '@/components/_shared/layouts/dashboard/standardized-element-height-layout';
export const BusinessIntelligenceOverviewComponent: FC<React.PropsWithChildren<unknown>> = props => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [containerHeight, setContainerHeight] = useState('auto');
  useEffect(() => {
    const updateHeight = () => {
      if (containerRef.current) {
        const viewportHeight = window.innerHeight;
        const rect = containerRef.current.getBoundingClientRect();
        const availableHeight = viewportHeight - rect.top;
        setContainerHeight(`${availableHeight}px`);
      }
    };
    updateHeight();
    window.addEventListener('resize', updateHeight);
    return () => window.removeEventListener('resize', updateHeight);
  }, []);
  return <StandardizedElementHeightLayout statCards={<BusinessIntelligenceStatCards />} charts={<div ref={containerRef} className="grid grid-cols-1 gap-4 m-2 mx-4 md:grid-cols-12" style={{
    minHeight: containerHeight,
    height: containerHeight
  }}>
          <div className="h-full md:col-span-4">
            <UnresolvedIssuesComponent />
          </div>
          <div className="grid h-full gap-4 grid-rows-12 md:col-span-8">
            <div className="row-span-5">
              <NetPromoterScoreComponent />
            </div>
            <div className="row-span-7">
              <BusinessIntelligenceSalesHistorySplineChart />
            </div>
          </div>
        </div>} data-sentry-element="StandardizedElementHeightLayout" data-sentry-component="BusinessIntelligenceOverviewComponent" data-sentry-source-file="business-intelligence-overview-component.tsx" />;
};
BusinessIntelligenceOverviewComponent.propTypes = {};