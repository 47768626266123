import { npsMapTypeData } from '@/lib/types/rtk-types/chart-data';
import { FC } from 'react';
import { NpsScore } from './nps-score';
interface BusinessIntelligenceOverviewNpsPercentagesProps {
  npsCountAndPercentages?: npsMapTypeData | null;
}
export const BusinessIntelligenceOverviewNpsPercentages: FC<React.PropsWithChildren<BusinessIntelligenceOverviewNpsPercentagesProps>> = ({
  npsCountAndPercentages
}) => {
  return <div className="flex w-full justify-around" data-sentry-component="BusinessIntelligenceOverviewNpsPercentages" data-sentry-source-file="business-intelligence-overview-nps-percentages.tsx">
      <NpsScore percentage={npsCountAndPercentages?.detractor?.percentage ?? 0} npsType="Detractors" data-sentry-element="NpsScore" data-sentry-source-file="business-intelligence-overview-nps-percentages.tsx" />
      <NpsScore percentage={npsCountAndPercentages?.passive?.percentage ?? 0} npsType="Passives" data-sentry-element="NpsScore" data-sentry-source-file="business-intelligence-overview-nps-percentages.tsx" />

      <NpsScore percentage={npsCountAndPercentages?.promoter?.percentage ?? 0} npsType="Promoters" data-sentry-element="NpsScore" data-sentry-source-file="business-intelligence-overview-nps-percentages.tsx" />
    </div>;
};