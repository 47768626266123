import { FC } from 'react';

// interface NpsDonutChartPlaceholderProps {}

export const NpsDonutChartPlaceholder: FC<React.PropsWithChildren<unknown>> = props => {
  return <div className="mt-2 flex h-[21vh] flex-col items-center justify-center px-4 text-center 2xl:h-[23.5vh]" data-sentry-component="NpsDonutChartPlaceholder" data-sentry-source-file="nps-donut-chart-placeholder.tsx">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-12 w-12 text-gray-400 " data-sentry-element="svg" data-sentry-source-file="nps-donut-chart-placeholder.tsx">
        <path d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5" data-sentry-element="path" data-sentry-source-file="nps-donut-chart-placeholder.tsx" />
      </svg>
      <h3 className="mt-2 text-sm font-medium text-gray-500">
        No feedback responses available
      </h3>
    </div>;
};