import {
  NpsDonutTimeframeDataValue,
  npsMapTypeData
} from './../types/rtk-types/chart-data';
export const useNpsPromoterScore = (
  npsDataTimeframe: NpsDonutTimeframeDataValue
) => {
  const npsMapData: npsMapTypeData = {
    promoter: {
      count: 0,
      percentage: 0
    },
    passive: {
      count: 0,
      percentage: 0
    },
    detractor: {
      count: 0,
      percentage: 0
    }
  };

  if (npsDataTimeframe?.total_responses === 0) {
    return null;
  } else {
    const totalCount = npsDataTimeframe?.total_responses ?? 0;

    npsDataTimeframe?.response_breakdown?.map((value) => {
      switch (value.choice_value) {
        case '4':
          npsMapData.passive.count = value?.count ?? 0;
          npsMapData.passive.percentage = Math.round(
            (npsMapData?.passive?.count / totalCount) * 100
          );
          break;

        case '5':
          npsMapData.promoter.count = value?.count ?? 0;
          npsMapData.promoter.percentage = Math.round(
            (npsMapData?.promoter?.count / totalCount) * 100
          );
          break;

        default:
          npsMapData.detractor.count += value?.count ?? 0;
          npsMapData.detractor.percentage = Math.round(
            (npsMapData?.detractor?.count / totalCount) * 100
          );
          break;
      }
    });
  }
  return npsMapData;
};
export const useCalculateNpsScores = (
  npsDataTimeframe: NpsDonutTimeframeDataValue | undefined
) => {
  if (!npsDataTimeframe) return 0;
  let promoterCount = 0;
  let detractorCount = 0;
  if (npsDataTimeframe?.total_responses === 0) {
    null;
  } else {
    npsDataTimeframe?.response_breakdown?.map((value) => {
      switch (value.choice_value) {
        case '5':
          promoterCount += value?.count ?? 0;
          break;
        case '4':
          null;
          break;
        default:
          detractorCount += value?.count ?? 0;
          break;
      }
    });
    if (npsDataTimeframe?.total_responses) {
      const npsFinalScore =
        (promoterCount / npsDataTimeframe?.total_responses -
          detractorCount / npsDataTimeframe?.total_responses) *
        100;

      //round score
      return Math.round(npsFinalScore);
    }
  }
};
