import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
interface UnresolvedIssuesHeaderProps {
  itemsToReview: number;
  assignedItemsCount: number;
}
export const UnresolvedIssuesHeader: FC<React.PropsWithChildren<UnresolvedIssuesHeaderProps>> = props => {
  const {
    itemsToReview,
    assignedItemsCount
  } = props;
  return <>
      <Typography variant="h6" sx={{
      padding: '20px 0px 0px 18px',
      fontWeight: 'regular'
    }} data-sentry-element="Typography" data-sentry-source-file="unresolved-issues-header.tsx">
        Items to Review:
        <span className="ml-1 font-proximaBold">{itemsToReview}</span>
      </Typography>
      <Typography variant="h6" sx={{
      padding: '5px 0px 20px 18px',
      fontWeight: 'regular'
    }} data-sentry-element="Typography" data-sentry-source-file="unresolved-issues-header.tsx">
        Assigned to You:
        <span className="ml-1 font-proximaBold">{assignedItemsCount}</span>
      </Typography>
    </>;
};
UnresolvedIssuesHeader.propTypes = {
  itemsToReview: PropTypes.number.isRequired,
  assignedItemsCount: PropTypes.number.isRequired
};