import * as React from 'react';
import type { FC } from 'react';
import { Customer } from '@/lib/types/communication-center';
import dayjs from 'dayjs';
import { useCreatedAtDate } from '@/lib/hooks/use-created-at-date';
import clsx from 'clsx';
interface CustomerLatestTimeDateProps {
  customer: Customer;
  isLoading: boolean;
}
export const CustomerLatestTimeDate: FC<React.PropsWithChildren<CustomerLatestTimeDateProps>> = props => {
  const {
    customer,
    isLoading
  } = props;
  const date = dayjs(customer.recent_activity_at);
  const createdTime = useCreatedAtDate(date);
  return <div className={clsx('flex space-x-2 py-1', {
    'animate-pulse bg-slate-200': !isLoading
  })} data-sentry-component="CustomerLatestTimeDate" data-sentry-source-file="customer-latest-time-date.tsx">
      <span>{createdTime}</span>
    </div>;
};