import type { FC } from 'react';
import { useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { BusinessIntelligenceSalesHistorySplineChartOptions } from './business-intelligence-sale-history-spline-chart-options';
import { Card, CircularProgress } from '@mui/material';
import { SectionTitleDescriptionComponent } from '@/components/_shared/page-elements/section-header';
import { useAppDispatch, useAppSelector } from '@/lib/state/hooks';
import { BaseSplineDataObject } from '@/lib/types/rtk-types/chart-data';
import dayjs from 'dayjs';
import { useGetSalesHistorySplineQuery } from '@/lib/state/services/business-intelligence-overview-api';
import { setSalesSplineOptions } from '@/lib/state/slices/sales-spline-slice';
export interface FinalSplineChartData {
  labels?: string[];
  previous?: number[];
  current?: number[];
}
interface SplineDataObject {
  date_label?: string;
  sales?: number;
}

// Default values
const defaultSplineChartData: FinalSplineChartData = {
  labels: [],
  previous: [],
  current: []
};
export const BusinessIntelligenceSalesHistorySplineChart: FC<React.PropsWithChildren> = () => {
  const {
    data: splineData,
    isLoading,
    error,
    refetch
  } = useGetSalesHistorySplineQuery();
  const timeframe_status = useAppSelector(state => state.timeframe.status);
  const dispatch = useAppDispatch();
  const splineDataState = useAppSelector(state => state.setSalesSpline.salesSplineOptions);
  useEffect(() => {
    refetch();
  }, [timeframe_status]);
  useEffect(() => {
    if (error) {
      console.error(error);
      return;
    }
    const splineDataTimeframe = splineData?.data?.[timeframe_status];
    const current = splineDataTimeframe?.data?.current?.map((dateAndValue: BaseSplineDataObject) => (dateAndValue.sales ?? 0) / 100) || defaultSplineChartData.current;
    const previous = splineDataTimeframe?.data?.previous?.map((dateAndValue: SplineDataObject) => (dateAndValue.sales ?? 0) / 100) || defaultSplineChartData.previous;
    const labels = splineDataTimeframe?.data?.current?.map((dateAndValue: SplineDataObject) => dayjs(dateAndValue.date_label).format('MM/DD')) || defaultSplineChartData.labels;
    const finalSplineChartData: FinalSplineChartData = {
      labels: labels,
      previous: previous,
      current: current
    };
    dispatch(setSalesSplineOptions(BusinessIntelligenceSalesHistorySplineChartOptions(finalSplineChartData)));
  }, [splineData, timeframe_status, isLoading, error]);
  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>An error occurred</div>;
  }
  const returnChart = () => {
    if (splineData && !isLoading) {
      const component = <div className="w-full px-2">
          {timeframe_status === 'seven_day' && <HighchartsReact highcharts={Highcharts} options={splineDataState} />}
          {timeframe_status === 'four_week' && <HighchartsReact highcharts={Highcharts} options={splineDataState} />}
          {timeframe_status === 'six_month' && <HighchartsReact highcharts={Highcharts} options={splineDataState} />}
        </div>;
      return component;
    } else {
      return <div className="flex flex-col items-center justify-center w-full h-60">
          <CircularProgress />
          <div className="p-4 text-gray-500 text-md font-proximaExtraBold">
            in the else isLoading is true or splineData is null
          </div>
        </div>;
    }
  };
  return <Card sx={{
    boxShadow: 1,
    height: '100%',
    px: 2
  }} data-sentry-element="Card" data-sentry-component="BusinessIntelligenceSalesHistorySplineChart" data-sentry-source-file="business-intelligence-sale-history-spline-chart.tsx">
      <SectionTitleDescriptionComponent title="Sales History" data-sentry-element="SectionTitleDescriptionComponent" data-sentry-source-file="business-intelligence-sale-history-spline-chart.tsx" />
      {returnChart()}
    </Card>;
};