import { api } from './api';
import {
  TotalSalesStatCardData,
  TotalPurchasesStatCardData,
  NewCustomersStatCardData,
  TotalCustomersStatCardData,
  LostCustomersStatCardData
} from '@/lib/types/rtk-types/stat-card-data';
import type { CustomerData } from '../../types/communication-center';

import {
  NpsDonutData,
  SalesHistorySplineData
} from '@/lib/types/rtk-types/chart-data';

export const businessIntelligenceOverviewApi = api.injectEndpoints({
  endpoints: (build) => ({
    getTotalSalesStatCard: build.query<TotalSalesStatCardData, void>({
      query: () => 'v2/charts/total_sales_stat_card',
      providesTags: ['BusinessIntelligenceOverviewData']
    }),
    getLostCustomersStatCard: build.query<LostCustomersStatCardData, void>({
      query: () => 'v2/charts/lost_customers_stat_card',
      providesTags: ['BusinessIntelligenceOverviewData']
    }),
    getTotalPurchasesStatCard: build.query<TotalPurchasesStatCardData, void>({
      query: () => 'v2/charts/total_purchases_stat_card',
      providesTags: ['BusinessIntelligenceOverviewData']
    }),
    getNewCustomersStatCard: build.query<NewCustomersStatCardData, void>({
      query: () => 'v2/charts/new_customers_stat_card',
      providesTags: ['BusinessIntelligenceOverviewData']
    }),
    getTotalCustomersStatCard: build.query<TotalCustomersStatCardData, void>({
      query: () => 'v2/charts/total_customers_stat_card',
      providesTags: ['BusinessIntelligenceOverviewData']
    }),
    getSalesHistorySpline: build.query<SalesHistorySplineData, void>({
      query: () => 'v2/charts/sales_spline',
      providesTags: ['BusinessIntelligenceOverviewData']
    }),
    getNpsDonut: build.query<NpsDonutData, void>({
      query: () => 'v2/charts/nps_donut',
      providesTags: ['BusinessIntelligenceOverviewData']
    }),
    getRecentActivityList: build.query<CustomerData, void>({
      query: () => 'v2/unresolved_customers',
      providesTags: ['BusinessIntelligenceOverviewData']
    })
  })
});

export const {
  useGetTotalSalesStatCardQuery,
  useGetTotalPurchasesStatCardQuery,
  useGetLostCustomersStatCardQuery,
  useGetNewCustomersStatCardQuery,
  useGetTotalCustomersStatCardQuery,
  useGetSalesHistorySplineQuery,
  useGetNpsDonutQuery,
  useGetRecentActivityListQuery
} = businessIntelligenceOverviewApi;
