import * as React from 'react';
import type { FC } from 'react';
import clsx from 'clsx';
import Str from '@supercharge/strings';
import { Customer } from '@/lib/types/communication-center';
interface CustomerAssignedAgentProps {
  customer: Customer;
  isLoading: boolean;
}
export const CustomerAssignedAgent: FC<React.PropsWithChildren<CustomerAssignedAgentProps>> = props => {
  const {
    customer,
    isLoading
  } = props;
  let currentAssignedAgent;

  //check if customer has current_assignment, if so set currentAssignedAgent to customer.current_assignment.user.name
  if (customer.current_assignment) {
    currentAssignedAgent = customer?.current_assignment?.user?.name ?? 'N/A';
  }
  return <>
      <div className={clsx('mx-2 text-sm text-gray-500 ', {
      'animate-pulse bg-slate-200': !isLoading
    })}>
        {Str(currentAssignedAgent && `(${currentAssignedAgent})`).limit(19, '...').get()}
      </div>
    </>;
};