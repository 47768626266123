import { FinalSplineChartData } from '@/components/dashboard/business-intelligence/intelligence-overview/components/sales-history-spline-chart/business-intelligence-sale-history-spline-chart';

export const BusinessIntelligenceSalesHistorySplineChartOptions = (
  data: FinalSplineChartData
) => {
  const finalSplineChartData = data as FinalSplineChartData;

  const current = finalSplineChartData?.current;
  const labels = finalSplineChartData?.labels;
  const previous = finalSplineChartData?.previous;

  return {
    chart: {
      type: 'areaspline',
      backgroundColor: 'transparent',
      height: '31.5%'
    },
    accessibility: {
      enabled: false
    },
    title: {
      text: 'chart id',
      align: 'left',
      style: {
        color: 'transparent',
        fontWeight: 'bold'
      }
    },
    legend: {
      align: 'right',
      verticalAlign: 'top',
      floating: true,
      squareSymbol: false,
      symbolRadius: 0,
      symbolWidth: 0,
      symbolHeight: 0,
      y: -15,
      itemStyle: {
        fontWeight: 'light',
        color: '#6b7280'
      },
      useHTML: true,
      labelFormatter: function (this: Highcharts.Series): string {
        return (
          '<div><div class="inline-block mb-0.5 mr-1.5" style="width:23px;height:3px;background-color:' +
          this?.color +
          '"></div>' +
          this?.name +
          '</div>'
        );
      }
    },
    xAxis: {
      type: 'category',
      categories: labels,
      labels: {
        align: 'center',
        y: 25,
        style: {
          fontSize: '.75rem',
          color: '#6b7280'
        }
      }
    },
    yAxis: {
      labels: {
        format: '${text}', // The $ is literally a dollar unit
        style: {
          fontSize: '.75rem',
          color: '#6b7280'
        }
      },
      title: {
        text: null
      }
    },
    tooltip: {
      backgroundColor: 'white',
      shared: true,
      useHTML: true,
      formatter: function (
        this: Highcharts.TooltipFormatterContextObject
      ): string {
        const newDate = new Date(this.x as string);
        const month = newDate.toLocaleString('default', { month: 'long' });
        const dateString = JSON.stringify(this.x);
        const day = dateString.substring(4, 6);
        const previousPeriod =
          this.points &&
          this.points[1] &&
          this.points[1].y &&
          String(this.points[1].y).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        const currentPeriod = String(this.y).replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ','
        );
        if (
          (this?.points && this?.points[0]?.series?.data?.length > 7) ||
          (this?.points && this?.points[0]?.series?.data?.length === 4)
        ) {
          return `<div style="display: flex; flex-direction: column; padding: 5px;">
                                        <span style="text-align: center; padding-top: 5px">Week of ${month} ${day}</span>
                                        <b style="text-align: center;color: white; padding-top: 5px;"><span style="color: ${this?.points[0]?.color}">Current Week: $${currentPeriod}</span></b>
                                        <b style="text-align: center;color: white; padding-top: 5px;"><span style="color: ${this?.points[1]?.color}">${this?.points[0]?.series?.data?.length} Weeks Prior: $${previousPeriod}</span></b>
                                    </div>`;
        } else if (this?.points) {
          return `<div style="display: flex; flex-direction: column; padding: 5px;">
                                        <span style="text-align: center; padding-top: 5px">Day of ${month} ${day}</span>
                                        <b style="text-align: center;color: white; padding-top: 5px;"><span style="color: ${this?.points[0]?.color}">Current Day: $${currentPeriod}</span></b>
                                        <b style="text-align: center;color: white;"><span style="color: ${this?.points[1]?.color};">${this?.points[0]?.series?.data?.length} Days Prior: $${previousPeriod}</span></b>
                                    </div>`;
        } else {
          return `<div style="display: flex; flex-direction: column; padding: 5px;">
                                        <span style="text-align: center; padding-top: 5px">Day of ${month} ${day}</span>
                                        <b style="text-align: center;color: white; padding-top: 5px;"><span >Current Day: $${currentPeriod}</span></b>
                                        <b style="text-align: center;color: white;"><span >Days Prior: $${previousPeriod}</span></b>
                                    </div>`;
        }
      },

      borderRadius: 15,
      borderWidth: 0,
      style: {
        fontSize: '14px'
      }
    },
    credits: {
      enabled: false
    },
    plotOptions: {
      series: {
        pointPlacement: 'on'
      },
      areaspline: {
        fillOpacity: 0.1
      }
    },
    series: [
      {
        name: 'Current Period',
        data: current,
        color: '#FF792A',
        lineColor: '#FF792A',
        marker: {
          symbol: 'circle',
          fillColor: '#FFFFFF',
          lineWidth: 2,
          lineColor: '#FF792A'
        }
      },
      {
        name: 'Previous Period',
        data: previous,
        color: '#ffca2a',
        lineColor: '#ffca2a',
        marker: {
          enabled: false
        }
      }
    ],
    exporting: {
      enabled: false
    }
  };
};
