import { Customer } from '@/lib/types/communication-center';
import * as React from 'react';
import type { FC } from 'react';
import clsx from 'clsx';
interface CustomerAssignedStatusProps {
  customer: Customer;
  isLoading: boolean;
}
type StatusStyles = {
  [key: string]: string;
  Unresolved: string;
  Pending: string;
  'High Priority': string;
  Resolved: string;
};

// const getCustomerStatus = (customer: Customer): string | undefined => {
//   if (customer.tags) {
//     const validTags = ['Unresolved', 'Pending', 'High Priority', 'Resolved'];
//     const tagNames = customer.tags.map((tag) => tag.name);
//     const matchingTags = tagNames.filter((name) => validTags.includes(name));
//     if (matchingTags.length > 0) {
//       return matchingTags[0];
//     }
//   }
//   return undefined;
// };

export const CustomerAssignedStatus: FC<React.PropsWithChildren<CustomerAssignedStatusProps>> = props => {
  const {
    customer,
    isLoading
  } = props;
  // const currentStatusName: keyof StatusStyles | undefined =
  //   getCustomerStatus(customer);

  // format status from snake case to title case
  const formattedStatus = customer?.status ? customer?.status.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') : null;
  return <>
      {formattedStatus && <span className={clsx('inline-flex items-center rounded-full px-2.5 py-0.5 pt-1 text-xs font-medium', {
      'animate-pulse bg-slate-300': !isLoading,
      'bg-[#e0f8ee] text-green-800': formattedStatus === 'Resolved',
      'bg-[#FEF3C7] text-yellow-800': formattedStatus === 'Pending',
      'bg-red-500 font-proximaSemiBold text-white': formattedStatus === 'High Priority',
      'bg-[#FEE2E2] text-[#bc243F]': formattedStatus === 'Unresolved'
    })}>
          <svg className={clsx('-ml-0.5 -mt-0.5 mr-1.5 h-2 w-2', {
        ' text-green-800': formattedStatus === 'Resolved',
        ' text-yellow-800': formattedStatus === 'Pending',
        ' text-white': formattedStatus === 'High Priority',
        ' text-[#bc243F]': formattedStatus === 'Unresolved'
      })} fill="currentColor" viewBox="0 0 8 8">
            <circle cx={4} cy={4} r={3} />
          </svg>
          {formattedStatus}
        </span>}
    </>;
};