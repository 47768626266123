import * as React from 'react';
import type { FC } from 'react';
import clsx from 'clsx';
import { Customer } from '@/lib/types/communication-center';
interface CustomerNameProps {
  customer: Customer;
  isLoading: boolean;
}
export const CustomerName: FC<React.PropsWithChildren<CustomerNameProps>> = props => {
  const {
    customer,
    isLoading
  } = props;
  const uppercaseFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  return <div className={clsx('truncate font-medium text-blue-500 underline hover:text-blue-700', {
    'animate-pulse bg-slate-200': !isLoading
  })} data-sentry-component="CustomerName" data-sentry-source-file="customer-name.tsx">
      {customer.full_name ? uppercaseFirstLetter(customer.full_name) : customer.first_name && customer.last_name ? uppercaseFirstLetter(customer.first_name + ' ' + customer.last_name) : customer.email ? customer.email : customer.mobile ? customer.mobile : `Name Not Captured (${customer.recent_activity})`}
    </div>;
};