import * as React from 'react';
import type { FC } from 'react';
interface NpsScoreProps {
  percentage: number;
  npsType: 'Promoters' | 'Passives' | 'Detractors';
}
export const NpsScore: FC<React.PropsWithChildren<NpsScoreProps>> = props => {
  const {
    percentage,
    npsType
  } = props;
  return <>
      <div className="text-center">
        {npsType === 'Detractors' && <>
            <strong className="font-proximaExtraBold text-3xl text-red-500">
              {percentage}%
            </strong>
            <div className="pt-2 text-sm text-gray-500">
              <div>{npsType}</div>
              <div>(Negative)</div>
            </div>
          </>}
        {npsType === 'Passives' && <>
            <strong className="font-proximaExtraBold text-3xl text-yellow-500">
              {percentage}%
            </strong>
            <div className="pt-2 text-sm text-gray-500">
              <div>{npsType}</div>
              <div>(Neutral)</div>
            </div>
          </>}

        {npsType === 'Promoters' && <>
            <strong className="font-proximaExtraBold text-3xl text-green-500">
              {percentage}%
            </strong>
            <div className="pt-2 text-sm text-gray-500">
              <div>{npsType}</div>
              <div>(Positive)</div>
            </div>
          </>}
      </div>
    </>;
};