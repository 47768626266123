import * as React from 'react';
import type { FC } from 'react';
import clsx from 'clsx';
interface CustomerLatestMessageProps {
  most_recent_message_body?: string;
  isLoading: boolean;
}
export const CustomerLatestMessage: FC<React.PropsWithChildren<CustomerLatestMessageProps>> = props => {
  const {
    most_recent_message_body,
    isLoading
  } = props;
  return <>
      <div className={clsx('w-4/5 text-xs text-gray-500 line-clamp-1', {
      'animate-pulse bg-slate-200': !isLoading
    })}>
        {most_recent_message_body}
      </div>
    </>;
};