interface Props {
  series1: number[] | null | undefined;
  series2: number[] | null | undefined;
  npsScore: number | null | undefined;
}

export const BusinessIntelligenceOverviewDonutChartOptions = (props: Props) => {
  let { series1, series2, npsScore } = props;

  series1 = series1 || [];
  series2 = series2 || [];
  npsScore = npsScore || 0;

  const colors = ['#07C674', '#EAB305', '#E0264A'];
  const lightColors = ['#D8FDED', '#FEEFC2', '#F7C9D2'];
  const categories = ['Promoters', 'Passives', 'Detractors'];
  const data = [];
  const drilldown = [];

  if (series1) {
    for (let i = 0; i < categories?.length; i += 1) {
      data.push({
        name: categories[i],
        y: series1[i],
        color: colors[i]
      });
    }
  }
  if (series2) {
    for (let i = 0; i < categories?.length; i += 1) {
      drilldown.push({
        name: categories[i],
        y: series2[i],
        color: lightColors[i]
      });
    }
  }

  const score = npsScore ? Math.round(npsScore) : 0;

  return {
    chart: {
      type: 'pie',
      height: '78%',
      backgroundColor: 'transparent'
    },

    credits: {
      enabled: false
    },
    exporting: {
      enabled: false
    },
    accessibility: {
      enabled: false
    },

    title: {
      text: '<div class="text-2xl font-proximaBold">' + score + '</div>',
      align: 'center',
      verticalAlign: 'middle',
      y: 23
    },

    plotOptions: {
      pie: {
        shadow: true,
        center: ['50%', '50%'],
        cursor: 'pointer',
        dataLabels: {
          enabled: false
        },
        borderWidth: 0,
        borderColor: '#F8F9FA',
        innerSize: '50%'
      }
    },
    tooltip: {
      backgroundColor: 'white',
      shared: true,
      useHTML: true,
      formatter: function (
        this: Highcharts.TooltipFormatterContextObject
      ): string {
        if (this?.series?.name === 'Sample B') {
          return `<div style="display: flex; flex-direction: column; padding: 5px;">
                                        <span style="text-align: center;  padding-top: 5px">${
                                          this?.key
                                        }</span>
                                        <b style="text-align: center; color: ${
                                          colors[this?.point?.index]
                                        }; padding-top: 5px;">${this.y}%</b>
                                    </div>`;
        } else {
          return `<div style="display: flex; flex-direction: column; padding: 5px;">
                                        <span style="text-align: center;  padding-top: 5px">${this?.key}</span>
                                        <b style="text-align: center; color: ${this?.point?.color}; padding-top: 5px;">${this?.y}</b>
                                    </div>`;
        }
      },

      borderRadius: 15,
      borderWidth: 0
    },
    series: [
      {
        name: 'Sample A',
        data: data,
        colors: colors,
        borderWidth: 3,
        borderColor: 'white',
        size: '96%',
        dataLabels: {
          formatter: function (
            this: Highcharts.PointLabelObject
          ): string | null {
            if (this?.y && this?.y > 5) {
              return this?.point?.name;
            } else {
              return null;
            }
          },
          color: '#ffffff',
          distance: -20
        },
        shadow: {
          width: 6,
          offsetY: 3,
          opacity: 0.5,
          color: '#c8c8c8'
        }
      },
      {
        name: 'Sample B',
        data: drilldown,
        colors: lightColors,
        borderWidth: 3,
        borderColor: 'white',
        size: '75%',
        innerSize: '60%',
        dataLabels: {
          formatter: function (
            this: Highcharts.PointLabelObject
          ): string | null {
            if (this?.y && this?.y > 1) {
              return '<b>' + this?.point?.name + ':</b> ' + this?.y + '%';
            } else {
              return null;
            }
          }
        },
        shadow: {
          width: 5,
          offsetY: 3,
          opacity: 0.5,
          color: '#c8c8c8'
        },
        id: 'versions',
        showInLegend: false
      }
    ],

    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 400
          },
          chartOptions: {
            series: [
              {},
              {
                id: 'versions',
                dataLabels: {
                  enabled: false
                }
              }
            ]
          }
        }
      ]
    }
  };
};
