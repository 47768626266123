import React from 'react';
const UnresolvedIssuesEmptyState = () => {
  return <div className="flex flex-col items-center justify-end h-full py-8 text-center md:h-1/2 md:py-0" data-sentry-component="UnresolvedIssuesEmptyState" data-sentry-source-file="unresolved-issues-empty-state.tsx">
      <span className="text-lg text-gray-900 font-proximaBold md:text-2xl">
        Waiting for Customers
      </span>
      <span className="w-full pt-4 text-sm font-proximaNova text-zinc-500 md:w-7/12 md:pt-8">
        Once you receive your first response, customers will show up here in
        chronological order.
      </span>
    </div>;
};
export default UnresolvedIssuesEmptyState;